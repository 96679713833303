import React from 'react';
import './iconstyle.css';

export default function BookIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 379.74 517.16'>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_2-2' data-name='Layer 2'>
          <rect className='bookBackground' x='21.51' y='17.73' width='358.24' height='499.43' rx='4' />
          <rect className='bookBody' x='12.28' y='11.19' width='358.24' height='499.43' rx='4' />
          <rect className='bookBackground' width='358.24' height='499.43' rx='4' />
          <path className='bookBackground' d='M1.27,498.37l22,18.14-1.71-5.88L4.34,497.08Z' />
        </g>
        <g id='Layer_4' data-name='Layer 4'>
          <rect className='bookBody' x='142.44' y='222.9' width='80.32' height='13.75' />
          <rect className='bookBody' x='94.19' y='151.23' width='176.81' height='15.88' />
          <rect className='bookBody' x='64.61' y='115.17' width='229.03' height='15.88' />
        </g>
      </g>
    </svg>
  )
}