import React, { useState, useEffect } from 'react';
import { makeRequest, formatISODate } from '../../Utils';
import ProductListItem from '../../components/ProductListItem';
import { Link } from 'react-router-dom';
import './style.css';
import BestsellersHomepage from '../../components/BestsellersHomepage';
import GhostProductListItem from '../../components/GhostProduct';
import GhostGrid from '../../components/GhostGrid';
import SidesScroller from '../../components/SideScroller';

export default function Homepage() {
  const [newProducts, setNewProducts] = useState([]);
  const [upcomingProducts, setUpcomingProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [pubs, setPubs] = useState([]);
  const [eligible, setEligible] = useState([]);

  document.title = `Asterism Books | Online Bookstore and Wholesale for Independent Publishers`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Asterism Books is a trade distributor and online bookshop built and run by a group of independent publishers, including Sublunary Editions, Contra Mundum Press, 11:11 Press, Chatwin Books, Kerpunkt, Sagging Meniscus Press, and more.`);

  useEffect(() => {
    let nos = [];
    for (let i = 0; i < 38; i++) {
      let value = Math.random() < 0.5;
      if (i === 0) {
        nos.push(value);
      } else if (nos[i - 1] === true) {
        nos.push(false);
      } else {
        nos.push(value)
      }
    }
    setEligible(nos);
  }, [])

  const getPubs = async () => {
    let data = await makeRequest('publishers', 'GET');
    setPubs(data);
  }

  const getUpcomingProducts = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?afterDate=${endKey}&ascending=true`, 'GET');
    setUpcomingProducts(data.docs);
  }

  const getNewProducts = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?beforeDate=${endKey}&inStockNow=true`, 'GET');
    setNewProducts(data.docs);
  }

  const getBestsellers = async () => {
    let data = await makeRequest('bestsellers', 'GET');
    setBestSellers(data);
  }

  useEffect(() => {
    getPubs();
    getNewProducts();
    getUpcomingProducts();
  }, []);



  return (
    <div>
      <div>
        {/*<>
        <div className='newHead'><h2>New Releases</h2></div>
        <SidesScroller list={newProducts} />
        </>*/}
        <div className='homepageWelcome'>
          <div className='homepageWelcome__inner'>
            <div className='homepageTitle'>Welcome to <span className='homepageTitlePress'>Asterism</span>!</div>
            <div className='homepageDescription'>
              <p>Asterism Books is a trade distributor and online bookstore designed, built, and run by independent publishers. We aim to bring the best of small-press publishing together in one place, highlighting the quality and diversity of the independent publishing landscape.</p>
            </div>
          </div>
        </div>
        {/*<></>
        <div className='newHead'><h2>Recent Bestsellers</h2></div>
        <BestsellersHomepage />
        <div className='newHead'><h2>Coming Soon</h2></div>
        <SidesScroller list={upcomingProducts} />
        </>*/}
        <div className='newHead'><h2>New and Upcoming Titles</h2></div>
        <div className='lowerGrid'>
          <div className='newPubList'>
            <h2 className='newPubTitle'>Our Publishers</h2>
            <div className='newPubsContainer'>
              <div className='newPubsList'>
                {pubs.map((pub) =>
                  <div key={pub.id} className='homepub'>
                    <div className='homepub_info'>
                      <Link className='homepub_name' to={`/publisher/${pub.slug}`}>{pub.name}</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {<>
            {newProducts.length === 0 && <GhostGrid items={24} />}
            {newProducts.map((product, index) =>
              <ProductListItem key={product._id} product={product} featured={product.featuredQuote && eligible[index]} order={index} context={'homepage'} />
            )}
            {upcomingProducts.map((product, index) =>
              <ProductListItem key={product._id} product={product} featured={product.featuredQuote && eligible[index + 16]} context={'homepage'} order={newProducts.length + index} />
            )}
          </>}
        </div>
      </div>
    </div >
  )
}
