import { useEffect, useRef } from 'react'
import { modeState, pageviewState, sessionState } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { asaEndPageview, asaEndSession, asaPageview, asaSession, getCountry, uuid } from '../Utils';
import { useLocation } from 'react-router-dom';

const shouldSendPageview = (path) => {
  return !path.includes('/admin') && !path.includes('/pub/');
}

const getPageType = (path) => {
  // homepage, all books, publisher list, publisher page, product page, page
  if (path === '/') {
    return 'home';
  } else if (path.includes('/product/')) {
    return 'product';
  } else if (path.includes('/all-books') || path.includes('/new-books') || path.includes('/upcoming-books') || path.includes('/ebooks')) {
    return 'books';
  } else if (path.includes('/list/')) {
    return 'list';
  } else if (path.includes('/our-publishers')) {
    return 'publisherList'
  } else if (path.includes('/publisher')) {
    return 'publisher'
  } else if (path.includes('/account')) {
    return 'account'
  } else if (path.includes('/search')) {
    return 'search'
  } else {
    return 'page'
  }
}

export function useAnalytics() {
  const [session, setSession] = useRecoilState(sessionState);
  const [pageview, setPageview] = useRecoilState(pageviewState)
  const mode = useRecoilValue(modeState);

  const location = useLocation();

  const sendPageview = async (data) => {
    let response = await asaPageview(data);
    if (pageview.id) {
      asaEndPageview(pageview.id);
    }
    if (response.id) {
      setPageview({ id: response.id, pagetype: data.pagetype });
    }
  }

  window.addEventListener('beforeunload', () => {
    asaEndSession(session.id);
    asaEndPageview(pageview.id);
  })

  let lastPv = useRef(null);

  useEffect(() => {
    if (session && session.id && lastPv.current !== location.pathname + location.search && shouldSendPageview(location.pathname)) {
      lastPv.current = location.pathname + location.search; // De-dupe those pageviews!
      sendPageview({
        usertype: mode || 'retail',
        session: session.id,
        path: window.location.pathname,
        search: window.location.search,
        pagetype: getPageType(window.location.pathname)
      });
    }
  }, [location, session]);

  const isEven = (n) => {
    return n % 2 === 0;
  }

  // Check for existing group
  let stored = localStorage.getItem('asterismTestGroup');
  let seed = new Date().getTime();
  let group = stored ? stored : isEven(seed) ? 'A' : 'B';
  localStorage.setItem('asterismTestGroup', group);
  let sessionId = uuid() + '+' + new Date().getTime();
  let ref = document.referrer ? new URL(document.referrer).hostname : '';
  let campaign = '';
  /* Get campaign */
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('campaign')) {
    campaign = urlParams.get('campaign');
    urlParams.delete('campaign');
  }
  if (urlParams.get('setTestGroup')) {
    group = urlParams.get('setTestGroup');
    urlParams.delete('setTestGroup');
    localStorage.setItem('asterismTestGroup', group);
  }
  /* End get campaign */
  let country = getCountry();
  if (!session.id) {
    setSession({
      id: sessionId,
      country: country,
      group: group,
      campaign: campaign,
      browserwidth: window.innerWidth,
    });
    asaSession({
      id: sessionId,
      usertype: mode,
      testgroup: group,
      location: country,
      browserwidth: window.innerWidth,
      source: ref,
      campaign: campaign
    })
  }

  return session;
}