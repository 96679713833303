import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';

export default function Jobs() {

  document.title = `Careers | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Open career opportunities at Asterism Books.`);


  return (
    <div>
      <h1 className='page-header'>Career Opportunities at Asterism</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          <p>Asterism Books is a trade distributor, representing more than 150 of the best independent publishers from around the world. Located in a beautiful space in Seattle’s Pioneer Square, our team is made up of publishers, writers, translators, artists, and more, passionate about expanding the reach of books published by small presses.</p>
          <h3>Inventory Lead</h3>
          <div><strong>Applications Open through September 16th</strong></div>
          <p>
            We’re looking for an Inventory Associate to join our quickly growing team at Asterism Books. This position will be based out of our beautiful offices in Pioneer Square, Seattle for approximately 30 hours/week, Monday through Friday.
          </p>
          <div><strong>Job Duties</strong></div>
          <ul>
            <li>Receiving new inventory: Coordinating incoming shipments; unpacking boxes of books, checking for damage, shelving and boxing</li>
            <li>New inventory ordering: keep an eye on our stock levels to ensure we have sufficient copies of each title for projected sales; place stock requests from our publishers</li>
            <li>Keep our Marketing department informed of newly in-stock titles</li>
            <li>Coordinate with our shipping department on incoming titles with outstanding orders</li>
            <li>Answering customer/bookseller/publisher inquiries on stock levels and availability</li>
          </ul>
          <p>This job mixes great books with physical activity. It requires a mix of sitting and standing, with sustained periods of walking activity. Candidates will need to be comfortable with light to moderate lifting, including boxes up to 50lbs. Candidates must be comfortable on ladders.</p>

          <p> An ideal candidate would have some experience managing receiving and/or inventory, especially in a bookstore environment.</p>

          <p>Familiarity with the small press publishing world is a plus, but not required. Candidates must be dog-friendly.</p>

          <p>Starting pay is $20/hour.</p>

          <p>If interested, please send a resume and cover letter to careers@asterismbooks.com</p>

        </div>
        <div className='flex-two'>
          <p>All active job listings will be posted here. Please do not send resumes outside of open calls.
          </p>
        </div>
      </div>
    </div>
  )
}
